/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { colors } from "style/colors";
import { useAppSelector } from "../hooks";
import { Layout } from "./Layout";

interface Props {}

export const RequireAuth: React.FC<Props> = ({ children, ...props }) => {
  const session = useAppSelector((state) => state.session);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!session.authenticated) {
      navigate("/", { state: { from: location } });
    }
  }, [session.authenticated, location, navigate]);
  if (!session.authenticated) {
    return (
      <Layout
        css={css`
          background: ${colors.blue};
        `}
      >
        uitgelogd
      </Layout>
    );
  }
  return <>{children}</>;
};
