export const colors = {
  black: "#000000",
  white: "#ffffff",
  yellow: "#FFC100",
  green: "#00773C",
  blue: "#0042F8",
  pink: "#FFC5DC",
  red: "#FF3D17",
  orange: "#FF9F00",
};
