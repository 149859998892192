/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Layout } from "components/Layout";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { colors } from "style/colors";
import { mobile } from "style/mq";

interface Props {}

export const Intro2Page: React.FC<Props> = ({ children, ...props }) => {
  return (
    <Layout
      css={css`
        background: ${colors.pink};
      `}
    >
      <div
        css={css`
          font-family: Campora Classic;
          font-size: 121px;
          font-style: normal;
          font-weight: 900;
          line-height: 112px;
          letter-spacing: 0em;
          text-align: left;
          color: ${colors.blue};

          ${mobile} {
            font-size: 30px;
            line-height: 38px;
          }
        `}
      >
        Kies bij elk{" "}
        <span
          css={css`
            color: ${colors.red};
          `}
        >
          beroep
        </span>{" "}
        de{" "}
        <span
          css={css`
            color: ${colors.red};
          `}
        >
          kwaliteiten
        </span>{" "}
        waarvan jij denkt dat die daarbij{" "}
        <span
          css={css`
            color: ${colors.red};
          `}
        >
          passen.
        </span>
      </div>
      <div
        css={css`
          align-self: flex-end;
        `}
      >
        <Link to="/eilanden" className="button">
          <BsArrowRightCircleFill
            css={css`
              position: fixed;
              bottom: 32px;
              right: 32px;
              color: ${colors.red};
              font-size: 80px;
            `}
          />
        </Link>
      </div>
    </Layout>
  );
};
