/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dropdownIcon from "assets/images/ls_dropdown.png";
import { toPairs } from "lodash";
import { useContext, useState } from "react";
import { useConditionalEffect, useDelta } from "react-delta";
import { BsArrowDownCircleFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router";
import { colors } from "style/colors";
import { mobile } from "style/mq";
import { Layout } from "../components/Layout";
import { FirebaseContext } from "../firebase/firebase-provider";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useGetSchoolsQuery } from "../services/droombanen-api";
import { signIn, signOut } from "../services/session";

export const SignInPage = () => {
  const dispatch = useAppDispatch();
  const firebase = useContext(FirebaseContext);
  const [name, setName] = useState("");
  const [selectedSchool, setSelectedSchool] = useState<string>();
  const [selectedClassroom, setSelectedClassroom] = useState<string>();
  let location = useLocation();

  let from = location.state?.from?.pathname || "/uitleg";
  const session = useAppSelector((state) => state.session);

  const navigate = useNavigate();
  const delta = useDelta(session);
  useConditionalEffect(() => {
    navigate(from, { replace: true });
  }, delta && delta.prev && delta.curr.authenticated);

  const { data: schools } = useGetSchoolsQuery();

  return (
    <Layout
      css={css`
        background: ${colors.yellow};
      `}
    >
      <div
        css={css`
          font-family: Campora Classic;
          font-size: 121px;
          line-height: 112px;
          font-style: normal;
          font-weight: 900;
          letter-spacing: 0em;
          text-align: center;
          color: ${colors.blue};
          margin-bottom: 68px;
          ${mobile} {
            font-size: 50px;
          }
        `}
      >
        Inloggen
      </div>
      {session.authenticated && session.user && (
        <>
          <div
            css={css`
              text-align: center;
            `}
          >
            <div
              css={css`
                font-family: Circular Std;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 0em;
                margin-bottom: 32px;
              `}
            >
              ingelogd als {session.user.name}
            </div>
            <div
              css={css`
                font-family: Circular Std;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 0em;
                cursor: pointer;
                background: ${colors.black};
                color: ${colors.white};
                padding: 8px 32px;
                border-radius: 16px;
                margin-top: 8px;
                margin-bottom: 80px;
                display: inline-block;
              `}
              onClick={() => dispatch(signOut(firebase))}
            >
              uitloggen
            </div>
            <div>
              <BsArrowDownCircleFill
                css={css`
                  color: ${colors.blue};
                  font-size: 80px;
                  cursor: pointer;
                `}
                onClick={() => navigate("/eilanden")}
              />
            </div>
          </div>
        </>
      )}
      {!session.authenticated && (
        <>
          <div
            css={css`
              width: 640px;
              max-width: 90%;
              margin: 0 auto 80px;
            `}
          >
            <div
              css={css`
                font-family: Campora Classic;
                font-size: 35px;
                font-style: normal;
                font-weight: 400;
                line-height: 54px;
                letter-spacing: 0em;
                text-align: left;
                margin: 15px 20px;
                ${mobile} {
                  font-size: 26px;
                }
              `}
            >
              Wat is je naam?
            </div>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              css={css`
                font-family: Campora Classic;
                font-size: 35px;
                font-style: normal;
                font-weight: 400;
                line-height: 54px;
                letter-spacing: 0em;
                text-align: left;
                height: 84.86846923828125px;
                width: 100%;
                border-radius: 20px;
                border: none;
                padding: 15px 20px;
                margin-bottom: 28px;
                ${mobile} {
                  height: 40px;
                  font-size: 20px;
                }
              `}
            />
            <select
              onChange={(e) => setSelectedSchool(e.target.value)}
              defaultValue="placeholder"
              css={css`
                font-family: Campora Classic;
                font-size: 35px;
                font-style: normal;
                font-weight: 400;
                line-height: 54px;
                letter-spacing: 0em;
                text-align: left;
                height: 84.86846923828125px;
                width: 100%;
                border-radius: 20px;
                border: none;
                padding: 15px 20px;
                -moz-appearance: none; /* Firefox */
                -webkit-appearance: none; /* Safari and Chrome */
                appearance: none;
                position: relative;
                background-image: url(${dropdownIcon});
                background-repeat: no-repeat;
                background-position-x: calc(100% - 20px);
                background-position-y: 50%;
                margin-bottom: 28px;
                ${mobile} {
                  height: 40px;
                  font-size: 20px;
                  padding: 0 20px;
                  line-height: 40px;
                  background-size: 20px;
                }
              `}
            >
              <option disabled hidden value="placeholder">
                Kies je school
              </option>
              {toPairs(schools).map(([id, school]) => (
                <option value={id} key={`school-option-${id}`}>
                  {school.name}
                </option>
              ))}
            </select>
            {schools && selectedSchool && (
              <select
                onChange={(e) => setSelectedClassroom(e.target.value)}
                defaultValue="placeholder"
                css={css`
                  font-family: Campora Classic;
                  font-size: 35px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 54px;
                  letter-spacing: 0em;
                  text-align: left;
                  height: 84.86846923828125px;
                  width: 100%;
                  border-radius: 20px;
                  border: none;
                  padding: 15px 20px;
                  -moz-appearance: none; /* Firefox */
                  -webkit-appearance: none; /* Safari and Chrome */
                  appearance: none;
                  position: relative;
                  background-image: url(${dropdownIcon});
                  background-repeat: no-repeat;
                  background-position-x: calc(100% - 20px);
                  background-position-y: 50%;
                  ${mobile} {
                    height: 40px;
                    font-size: 20px;
                    padding: 0 20px;
                    line-height: 40px;
                    background-size: 20px;
                  }
                `}
              >
                <option disabled hidden value="placeholder">
                  Kies je klas
                </option>
                {toPairs(schools[selectedSchool].classrooms).map(
                  ([id, classroom]) => (
                    <option value={id} key={`classroom-option-${id}`}>
                      {classroom.name}
                    </option>
                  )
                )}
              </select>
            )}
          </div>

          <BsArrowDownCircleFill
            css={css`
              color: ${colors.blue};
              font-size: 80px;
              cursor: pointer;
              opacity: ${selectedClassroom && name && selectedSchool ? 1 : 0.2};
              margin: 0 auto;
              display: block;
            `}
            onClick={() =>
              selectedClassroom &&
              name &&
              selectedSchool &&
              dispatch(
                signIn({
                  app: firebase,
                  name,
                  classroom: selectedClassroom!,
                  school: selectedSchool!,
                })
              )
            }
          />
        </>
      )}
    </Layout>
  );
};
