import { take, toPairs, values } from "lodash";

export function controversy(qualities: { [key: string]: number }) {
  const counts = values(qualities).filter((count) => count > 0);
  const total = counts.reduce((acc, count) => acc + count, 0);
  if (total > 0) {
    return counts.length / total;
  }
  return null;
}

export function getLeastControversial(qualitiesPerJob: {
  [key: string]: { [key: string]: number };
}) {
  return take(
    //limit to the 10 most voted jobs
    toPairs(qualitiesPerJob)
      .filter(([jobId, qualities]) => {
        // filter out the ones with no vtes
        const total = values(qualities).reduce((acc, count) => acc + count, 0);
        return total > 1;
      })
      .sort((a, b) => {
        // sort with most votes on top
        const totalA = values(a[1]).reduce((acc, count) => {
          return acc + count;
        }, 0);
        const totalB = values(b[1]).reduce((acc, count) => {
          return acc + count;
        }, 0);
        return totalB - totalA;
      }),
    //pick 10 most voted
    10
  ).sort((a, b) => {
    // sort by least controversial
    const scores = { a: controversy(a[1]), b: controversy(b[1]) };
    if (scores.a === scores.b) {
      // if scores are equal, the one with most voted items gets sorted down
      return (
        values(a[1]).filter((item) => item !== 0).length -
        values(b[1]).filter((item) => item !== 0).length
      );
    }
    return (scores.a ? scores.a : 0) - (scores.b ? scores.b : 0);
  });
}
