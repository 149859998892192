/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { GlobalStyles } from "../style/GlobalStyles";

export const Layout: React.FC = ({ children, ...props }) => {
  return (
    <div
      css={css`
        height: 100vh;
        overflow-y: hidden;
        /* padding: 32px; */
      `}
      {...props}
    >
      <GlobalStyles />
      <div
        css={css`
          height: 100vh;
          display: flex;
          flex-direction: column;
          /* justify-content: center;
          align-items: center; */
          overflow-y: auto;
          margin: 0 auto;
        `}
      >
        <div
          css={css`
            width: 1370px;
            max-width: 90%;
            margin: 20vh auto 64px;
            padding: 0 32px;
          `}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
