import * as d3 from "d3";
import { Body } from "matter-js";
import p5Types, { Color } from "p5";
import { Circle } from "./Circle";

export class Blob {
  public bodies: Body[];
  public circles: Circle[];
  private p5: p5Types;
  public name: string;
  public id: string;
  public label: string;
  public color: Color;
  public textColor: Color;

  constructor(
    p5: p5Types,
    x: number,
    y: number,
    label: string,
    id: string,
    name: string,
    color: Color,
    textColor: Color
  ) {
    this.bodies = [];
    this.circles = [];
    this.p5 = p5;
    this.name = name;
    this.id = id;
    this.label = label;
    this.color = color;
    this.textColor = textColor;

    for (let i = 0; i < 10; i++) {
      const r = (p5.height * p5.width) / 25000;
      const circle = new Circle(
        p5,
        label,
        x + p5.random(0, p5.width / 100),
        y + p5.random(0, p5.height / 100),
        r,
        // i === 0
        false
      );
      this.circles.push(circle);
      this.bodies.push(circle.body);
    }
  }

  public getCenter(p5 = this.p5) {
    return this.circles
      .reduce(
        (acc, circle) =>
          acc.add(circle.body.position.x, circle.body.position.y),
        p5.createVector(0, 0)
      )
      .div(this.circles.length);
  }

  public show(p5 = this.p5) {
    let pointArr: [number, number][] = [];
    const padding = -25;
    this.circles.forEach((circle) => {
      const pad = circle.r + padding;
      const { x, y } = circle.body.position;
      pointArr = pointArr.concat([
        [x - pad, y - pad],
        [x - pad, y + pad],
        [x + pad, y - pad],
        [x + pad, y + pad],
      ]);
    });

    const hull = d3.polygonHull(pointArr);
    if (hull) {
      const path = d3.line().curve(d3.curveBasisClosed)(hull);
      if (path) {
        const bezierPoints = path
          .split(/[MC]/)
          .map((item) => item.split(",").map(parseFloat));

        if (bezierPoints.length > 1) {
          p5.stroke(255);
          p5.strokeWeight(10);
          p5.fill(this.color);
          p5.beginShape();

          p5.vertex(bezierPoints[1][0], bezierPoints[1][1]);

          for (let i = 2; i < bezierPoints.length; i++) {
            let point = bezierPoints[i];
            if (point.length === 6) {
              p5.bezierVertex(
                point[0],
                point[1],
                point[2],
                point[3],
                point[4],
                point[5]
              );
            }
          }

          p5.endShape(p5.CLOSE);
        }
      }
    }
  }
}
