import { FirebaseApp } from "@firebase/app";
import {
  getAuth,
  signInAnonymously,
  signOut as firebaseSignOut,
} from "@firebase/auth";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { RootState } from "store/configureStore";
import { Session, User } from "../types";
import { droombanenApi } from "./droombanen-api";

export const signIn = createAsyncThunk<
  { user: User; classroom: string; school: string; schoolClassroom: string },
  {
    app: FirebaseApp;
    name: string;
    classroom: string;
    school: string;
  }
>("session/signIn", async ({ app, name, classroom, school }, { dispatch }) => {
  const response = await signInAnonymously(getAuth(app));
  const user: User = { name, uid: response.user.uid };
  const schoolClassroom = `${school}-${classroom}`;
  dispatch(
    droombanenApi.endpoints.updateSession.initiate({
      user,
      classroom,
      school,
      schoolClassroom,
    })
  );
  return {
    user,
    classroom,
    school,
    schoolClassroom,
  };
});

export const signOut = createAsyncThunk(
  "session/signOut",
  async (app: FirebaseApp) => {
    await firebaseSignOut(getAuth(app));
  }
);

const initialState: Session = {
  user: null,
  authenticated: false,
  classroom: null,
  school: null,
  schoolClassroom: null,
};

const slice = createSlice({
  name: "session",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.authenticated = true;
      state.classroom = action.payload.classroom;
      state.school = action.payload.school;
      state.schoolClassroom = action.payload.schoolClassroom;
    });
    builder.addCase(signOut.fulfilled, () => initialState);
    builder.addCase(PURGE, () => initialState);
  },
});

export default slice;

export const selectCurrentUser = (state: RootState) => state.session.user;
