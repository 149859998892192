/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Layout } from "components/Layout";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { colors } from "style/colors";
import { useParams } from "react-router-dom";
import { useGetSchoolsQuery } from "services/droombanen-api";

interface Props {}

export const OverviewIntro: React.FC<Props> = ({ children, ...props }) => {
  const { classroom: classroomId, schoolId } = useParams();
  const {
    data: { school, classroom },
  } = useGetSchoolsQuery(undefined, {
    selectFromResult: (result) => ({
      data: {
        school:
          schoolId && result
            ? result.data
              ? result.data[schoolId]
              : null
            : null,
        classroom:
          schoolId && classroomId && result
            ? result.data
              ? result.data[schoolId].classrooms[classroomId]
              : null
            : null,
      },
    }),
  });
  return (
    <Layout
      css={css`
        background: ${colors.pink};
      `}
    >
      <div
        css={css`
          font-family: Campora Classic;
          font-size: 121px;
          font-style: normal;
          font-weight: 900;
          line-height: 112px;
          letter-spacing: 0em;
          text-align: left;
          color: ${colors.blue};
        `}
      >
        Klassenoverzicht van{" "}
        {classroom && (
          <span
            css={css`
              color: ${colors.red};
            `}
          >
            {classroom.name}
          </span>
        )}{" "}
        {school && (
          <span>
            {" "}
            van{" "}
            <span
              css={css`
                color: ${colors.red};
              `}
            >
              {school.name}
            </span>
          </span>
        )}
      </div>
      <div
        css={css`
          align-self: flex-end;
        `}
      >
        <Link
          to={`/overview/${schoolId}/${classroomId}/least-controversial`}
          className="button"
        >
          <BsArrowRightCircleFill
            css={css`
              position: fixed;
              bottom: 32px;
              right: 32px;
              color: ${colors.red};
              font-size: 80px;
            `}
          />
        </Link>
      </div>
    </Layout>
  );
};
