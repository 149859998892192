import { values, toPairs } from "lodash";
import { User } from "types";

export function getQualitiesPerJob(
  overview:
    | {
        [uid: string]: User & {
          jobs?:
            | { [key: string]: { qualities: { [key: string]: boolean } } }
            | undefined;
        };
      }
    | undefined
) {
  return overview
    ? values(overview).reduce((acc, session) => {
        if (session && session.jobs) {
          toPairs(session.jobs).forEach(([jobId, job]) => {
            if (!acc[jobId]) {
              acc[jobId] = {};
            }
            toPairs(job.qualities).forEach(([qualityId, checked]) => {
              if (checked) {
                if (!acc[jobId][qualityId]) {
                  acc[jobId][qualityId] = 0;
                }
                acc[jobId][qualityId]++;
              }
            });
          });
        }
        return acc;
      }, {} as { [key: string]: { [key: string]: number } })
    : ({} as { [key: string]: { [key: string]: number } });
}
