/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { IslandSimulation } from "components/IslandSimulation";
import React from "react";

interface Props {}

export const TestPage: React.FC<Props> = ({ children, ...props }) => {
  return (
    <div
      css={css`
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        .react-p5 {
          height: 100%;
        }
      `}
    >
      <IslandSimulation />
    </div>
  );
};
