import { StoreProvider } from "store/store-provider";
import React from "react";
import { FirebaseProvider } from "./firebase/firebase-provider";
import { AppRouter } from "./router/app-router";

function App() {
  return (
    <FirebaseProvider>
      <StoreProvider>
        <AppRouter />
      </StoreProvider>
    </FirebaseProvider>
  );
}

export default App;
