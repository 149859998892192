/** @jsxImportSource @emotion/react */
import { toPairs, uniqueId } from "lodash";
import "matter-attractors";
import Matter, { Bodies, Composite, Engine } from "matter-js";
import p5Types from "p5";
import { Blob } from "p5-classes/Blob";
import React, { useEffect, useRef } from "react";
import Sketch from "react-p5";
import { useGetIslandsQuery } from "services/droombanen-api";
import { Island } from "types";
import { useNavigate } from "react-router";

export const IslandSimulation: React.FC = (props) => {
  const engine = useRef(Engine.create());
  const blobs = useRef<Blob[]>([]);
  const p5 = useRef<p5Types>();
  const mouseDragged = useRef(false);

  const { data: islands } = useGetIslandsQuery();
  const prevIslands = useRef<{ [key: string]: Island }>();

  const navigate = useNavigate();

  useEffect(() => {
    if (islands && islands !== prevIslands.current && p5 && p5.current) {
      toPairs(islands).forEach(([islandId, island], i) => {
        const p5w = p5.current!.width;
        const p5h = p5.current!.height;
        const marginw = p5w / 5;
        const marginh = 100;
        const position = {
          x: p5.current!.random(marginw, p5w - marginw),
          y: marginh + (i / toPairs(islands).length) * (p5h - marginh * 2),
        };
        const label = uniqueId(islandId);
        const blob = new Blob(
          p5.current!,
          position.x,
          position.y,
          label,
          islandId,
          island.name,
          p5.current!.color(island.colors.background),
          p5.current!.color(island.colors.body)
        );
        blobs.current.push(blob);
        Composite.add(engine.current.world, blob.bodies);
      });
    }
    prevIslands.current = islands;
  }, [islands]);

  const handleDown = () => {
    mouseDragged.current = false;
  };

  const handleUp = (p5: p5Types) => {
    if (!mouseDragged.current) {
      let closestBlob: Blob | null = null;
      let closestDist: number | null = null;
      for (const blob of blobs.current) {
        const center = blob.getCenter();
        const dist = p5.dist(p5.mouseX, p5.mouseY, center.x, center.y);
        if (!closestDist || closestDist > dist) {
          closestBlob = blob;
          closestDist = dist;
        }
      }
      if (closestBlob) {
        navigate(`/eilanden/${closestBlob.id}`);
      }
    }
  };

  const handleMouseDragged = () => {
    mouseDragged.current = true;
  };

  const setup = (p5: p5Types, canvasParentRef: Element) => {
    const { clientWidth: cw, clientHeight: ch } = canvasParentRef;
    p5.createCanvas(cw, ch).parent(canvasParentRef);

    Matter.use("matter-attractors");
    engine.current.gravity.scale = 0;
    engine.current.timing.timeScale = 0.3;

    const paddingV = 100;
    const paddingH = cw / 20;

    Composite.add(engine.current.world, [
      Bodies.rectangle(cw / 2, 0, cw, paddingV, { isStatic: true }),
      Bodies.rectangle(cw / 2, ch, cw, paddingV, { isStatic: true }),
      Bodies.rectangle(0, ch / 2, paddingH, ch, { isStatic: true }),
      Bodies.rectangle(cw, ch / 2, paddingH, ch, { isStatic: true }),
    ]);

    p5.cursor("pointer");
  };

  const draw = (p5: p5Types) => {
    Engine.update(engine.current, p5.deltaTime);
    p5.background(255);

    for (const blob of blobs.current) {
      blob.show();
    }

    // draw circles
    // for (const blob of blobs.current) {
    //   for (const circle of blob.circles) {
    //     const {
    //       position: { x, y },
    //     } = circle.body;
    //     p5.push();
    //     p5.translate(x, y);
    //     p5.stroke(0);
    //     p5.strokeWeight(1);
    //     p5.fill(blob.color);
    //     p5.circle(0, 0, circle.r * 2);
    //     p5.pop();
    //   }
    // }

    const textSize = (p5.height * p5.width) / 25000 / 3;
    for (const blob of blobs.current) {
      const center = blob.getCenter();
      p5.stroke(blob.color);
      p5.strokeWeight(5);
      p5.fill(blob.textColor);
      p5.textAlign(p5.CENTER);
      p5.rectMode(p5.CENTER);
      p5.textSize(textSize);
      p5.text(blob.name, center.x, center.y);
    }
  };

  return (
    <Sketch
      setup={setup}
      draw={draw}
      mousePressed={handleDown}
      mouseReleased={handleUp}
      mouseDragged={handleMouseDragged}
      preload={(argP5) => {
        p5.current = argP5;
      }}
    />
  );
};
