/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Layout } from "components/Layout";
import { toPairs, values } from "lodash";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { colors } from "style/colors";
import { getQualitiesPerJob } from "utils/qualities-per-job";
import { trycatch } from "utils/trycatch";
import {
  useGetJobsQuery,
  useGetOverviewQuery,
} from "../services/droombanen-api";

interface Props {}

export const OverviewPage: React.FC<Props> = ({ children, ...props }) => {
  const { classroom, schoolId } = useParams();
  const { data: overview } = useGetOverviewQuery({ classroom, schoolId });
  const qualitiesPerJob = getQualitiesPerJob(overview);
  const mostVotedJobs = toPairs(qualitiesPerJob)
    .map(([jobId, qualities]) => ({
      id: jobId,
      count: values(qualities).reduce((acc, count) => {
        return acc + count;
      }, 0),
    }))
    .sort((a, b) => {
      return b.count - a.count;
    });
  const { data: jobs } = useGetJobsQuery();
  const navigate = useNavigate();

  return (
    <Layout
      css={css`
        background: ${colors.green};
      `}
    >
      <div
        css={css`
          font-family: Campora Classic;
          font-size: 75px;
          font-style: normal;
          font-weight: 900;
          line-height: 80px;
          letter-spacing: 0em;
          margin-bottom: 20px;
          text-align: center;
        `}
      >
        Beroepen
      </div>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        `}
      >
        {jobs &&
          mostVotedJobs.map(({ id: jobId, count }) => {
            const job = jobs[jobId];
            return (
              <div
                key={jobId}
                css={css`
                  margin: 10px;
                  width: 300px;
                  background: ${colors.white};
                  border-radius: 20px;
                  padding: 16px;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  cursor: pointer;
                `}
                onClick={() =>
                  navigate(`/overview/${schoolId}/${classroom}/job/${jobId}`)
                }
              >
                <img
                  src={trycatch(
                    () => require(`assets/illustrations/${job.image}`).default,
                    require("assets/illustrations/placeholder.png").default
                  )}
                  alt={job.name}
                  css={css`
                    width: 100%;
                    margin-bottom: 16px;
                  `}
                />
                <div
                  css={css`
                    font-family: Campora Classic;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 900;
                    letter-spacing: 0em;
                    margin-bottom: 20px;
                    text-align: center;
                  `}
                >
                  {job.name}
                </div>
                <div
                  css={css`
                    font-family: Circular Std;
                    font-size: 20px;
                  `}
                >
                  {count} {count === 1 ? "stem" : "stemmen"}
                </div>
              </div>
            );
          })}
      </div>
    </Layout>
  );
};
