/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { EilandenLayout } from "components/EilandenLayout";
import { IslandSimulation } from "components/IslandSimulation";
import { VoteCounter } from "components/VoteCounter";
import React from "react";
import { colors } from "style/colors";
import { mobile } from "../style/mq";
interface Props {}

export const Eilanden: React.FC<Props> = ({ children, ...props }) => {
  return (
    <EilandenLayout>
      <VoteCounter />
      <div
        css={css`
          flex: 1;
          padding: 0 70px;
          position: relative;
          background: ${colors.white};
          .react-p5 {
            height: 100%;
          }
          ${mobile} {
            padding: 0;
            height: 200vh;
          }
        `}
      >
        <IslandSimulation />
      </div>
    </EilandenLayout>
  );
};
