/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { Fragment, useEffect, useState } from "react";
import { Job } from "types";
import { LNoise } from "utils/noise";
import { useAnimationFrame } from "utils/use-animation-frame";
import useWindowDimensions from "utils/use-window-dimensions";
import { Goo } from "./Goo";

export const Blob: React.FC<{
  left: number;
  top: number;
  scale: number;
  color: string;
  noise: any;
  noiseIndexes: number[];
  onClick?: () => void;
}> = ({ left, top, scale, color, noise, noiseIndexes, onClick, ...props }) => {
  const { height } = useWindowDimensions();
  return (
    <div
      className="blob"
      css={css`
        position: absolute;
        top: ${(height / 100) * top}px;
        left: ${(height / 100) * left}px;
        background: ${color};
        width: ${(height / 100) * scale}px;
        height: ${(height / 100) * scale}px;
        border-radius: 100%;
      `}
      style={{
        transform: `translate(${noise(noiseIndexes[0])}%, ${noise(
          noiseIndexes[1]
        )}%)`,
      }}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      {...props}
    ></div>
  );
};

type Node = {
  id: string | null;
  name: string | null;
  scale: number;
  x: number;
  y: number;
  noiseIndexes: number[];
};

export const JobsBlobs: React.FC<{
  color: string;
  textColor: string;
  jobs: [string, Job][];
  islandId?: string;
  onClickJob: (jobId: string) => void;
}> = ({ color, textColor, jobs, islandId, onClickJob, ...props }) => {
  const [nodes, setNodes] = useState<{
    jobNodes: Node[];
    fakeNodes: Node[];
  }>({ jobNodes: [], fakeNodes: [] });

  useEffect(() => {
    if (!nodes.jobNodes.length && jobs.length) {
      const jobNodes = jobs.map((item, i) => transformNode(item, i, true));
      const fakeNodes: Node[] = [];
      let iteration = 0;
      while (fakeNodes.length < 20 && iteration < 500) {
        iteration += 1;
        const node = transformNode([null, null], fakeNodes.length, false, true);
        const isTooClose = [...jobNodes, ...fakeNodes].reduce(
          (acc, testNode) => {
            if (acc) {
              return acc;
            }
            const tooClose =
              Math.abs(testNode.x - node.x) <
                node.scale / 2 + testNode.scale / 2 + 10 &&
              Math.abs(testNode.y - node.y) <
                node.scale / 2 + testNode.scale / 2 + 10;
            return tooClose;
          },
          false as boolean
        );

        const isTooFar = [...jobNodes, ...fakeNodes].reduce((acc, testNode) => {
          if (acc) {
            return acc;
          }
          const isTooFar =
            Math.abs(testNode.x - node.x) >
              (node.scale / 2 + testNode.scale / 2) * 9 &&
            Math.abs(testNode.y - node.y) >
              (node.scale / 2 + testNode.scale / 2) * 9;
          return isTooFar;
        }, false as boolean);

        if (!isTooClose && !isTooFar) {
          fakeNodes.push(node);
        }
      }
      setNodes({
        jobNodes,
        fakeNodes,
      });
    }
  }, [jobs, nodes]);

  const [count, setCount] = React.useState(0);
  const [noise] = React.useState(new LNoise(Math.random()));

  const { height } = useWindowDimensions();

  useAnimationFrame((deltaTime) => {
    setCount((prevCount) => prevCount + deltaTime * 0.0003);
  });
  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        position: relative;
      `}
      {...props}
    >
      <Goo />
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        `}
      >
        <div
          className="blobs"
          css={css`
            opacity: 0.5;
          `}
        >
          {[...nodes.jobNodes, ...nodes.fakeNodes].map((job, i) => {
            return (
              <Fragment key={`blob-${i}`}>
                <Blob
                  left={job.x - Math.max(job.scale * 3, 30) / 2}
                  top={job.y - Math.max(job.scale * 3, 30) / 2}
                  color={color}
                  noise={(y: number) => noise.perlin2(count, y) * 15}
                  scale={Math.max(job.scale * 3, 30)}
                  noiseIndexes={job.noiseIndexes}
                />
              </Fragment>
            );
          })}
        </div>
        {nodes.fakeNodes.map((job, i) => {
          return (
            <Fragment key={`fakeblob-${i}`}>
              <Blob
                left={job.x}
                top={job.y}
                color={color}
                noise={(y: number) => noise.perlin2(count, y) * 15}
                scale={job.scale}
                noiseIndexes={job.noiseIndexes}
              />
            </Fragment>
          );
        })}
        {nodes.jobNodes.map((job, i) => {
          return (
            <Fragment key={`jobblob-${i}`}>
              <Blob
                left={job.x}
                top={job.y}
                color={color}
                noise={(y: number) => noise.perlin2(count, y) * 15}
                scale={job.scale}
                noiseIndexes={job.noiseIndexes}
                css={css`
                  cursor: pointer;
                `}
                onClick={() => {
                  if (job.id) {
                    onClickJob(job.id);
                  }
                }}
              />
            </Fragment>
          );
        })}
        {nodes.jobNodes.map((job, i) => {
          return (
            <Fragment key={`jobblobtext-${i}`}>
              <div
                css={css`
                  position: absolute;
                  top: ${(height / 100) * job.y}px;
                  left: ${(height / 100) * job.x}px;
                  color: ${textColor};
                  width: ${(height / 100) * job.scale}px;
                  height: ${(height / 100) * job.scale}px;
                  font-family: Campora Classic;
                  font-size: 17px;
                  font-style: normal;
                  font-weight: 900;
                  line-height: 17px;
                  letter-spacing: 0em;
                  text-align: center;
                  pointer-events: none;
                  padding: 8px;
                `}
                style={{
                  transform: `translate(${
                    noise.perlin2(count, job.noiseIndexes[0]) * 15
                  }%, ${45 + noise.perlin2(count, job.noiseIndexes[1]) * 15}%)`,
                }}
              >
                {job.name}
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
function transformNode(
  [id, job]: [string, Job] | null[],
  i: number,
  order?: boolean,
  small?: boolean
) {
  return {
    id: id ? id : null,
    name: job ? job.name : null,
    scale: small ? 2 + Math.random() * 4 : 10 + Math.random() * 10,
    x: (order ? 0.1 + ((i * 0.5) % 0.9) : Math.random() % 0.8) * 100,
    y: (order ? Math.floor(i / 2) * 0.5 : Math.random() % 0.8) * 100,
    noiseIndexes: [Math.random() * 100, Math.random() * 100],
    // scale: 1,
    // x: 1,
    // y: 1,
  };
}
