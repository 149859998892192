/** @jsxImportSource @emotion/react */
import { RequireAuth } from "components/RequireAuth";
import { Eiland } from "pages/Eiland";
import { Eilanden } from "pages/Eilanden";
import { Intro2Page } from "pages/Intro2Page";
import { IntroPage } from "pages/IntroPage";
import { OverviewIndex } from "pages/OverviewIndex";
import { OverviewJobPage } from "pages/OverviewJobPage";
import { OverviewLeastControversial } from "pages/OverviewLeastControversial";
import { OverviewMostControversial } from "pages/OverviewMostControversial";
import { OverviewPage } from "pages/OverviewPage";
import { SignInPage } from "pages/SignInPage";
import { TestPage } from "pages/TestPage";
import React from "react";
import { Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { OverviewIntro } from "../pages/OverviewIntro";
import { AnimatedSwitch } from "./AnimatedSwitch";

export const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <AnimatedSwitch direction="down">
        <Route path="/" element={<IntroPage />} />
        <Route path="test" element={<TestPage />} />
        <Route path="sign-in" element={<SignInPage />} />

        <Route path="overview" element={<OverviewIndex />} />
        <Route
          path="overview/:schoolId/:classroom"
          element={<OverviewIntro />}
        />
        <Route
          path="overview/:schoolId/:classroom/list"
          element={<OverviewPage />}
        />
        <Route
          path="overview/:schoolId/:classroom/job/:jobId"
          element={<OverviewJobPage />}
        />
        <Route
          path="overview/:schoolId/:classroom/most-controversial"
          element={<OverviewMostControversial />}
        />
        <Route
          path="overview/:schoolId/:classroom/least-controversial"
          element={<OverviewLeastControversial />}
        />

        <Route
          path="uitleg"
          element={
            <RequireAuth>
              <Intro2Page />
            </RequireAuth>
          }
        />

        <Route
          path="eilanden"
          element={
            <RequireAuth>
              <Eilanden />
            </RequireAuth>
          }
        />
        <Route
          path="eilanden/:islandId/beroep/:jobId"
          element={
            <RequireAuth>
              <Eiland />
            </RequireAuth>
          }
        />
        <Route
          path="eilanden/:islandId"
          element={
            <RequireAuth>
              <Eiland />
            </RequireAuth>
          }
        />
      </AnimatedSwitch>
    </BrowserRouter>
  );
};
