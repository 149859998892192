/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Layout } from "components/Layout";
import { toPairs, values } from "lodash";
import React from "react";
import { useParams } from "react-router";
import { colors } from "style/colors";
import { trycatch } from "utils/trycatch";
import {
  useGetJobsQuery,
  useGetOverviewQuery,
  useGetQualitiesQuery,
} from "../services/droombanen-api";

interface Props {
  jobId?: string;
}

export const OverviewJob: React.FC<Props> = ({ children, ...props }) => {
  const { classroom, jobId = props.jobId, schoolId } = useParams();
  const { data: overview } = useGetOverviewQuery({ classroom, schoolId });
  const { data: job } = useGetJobsQuery(undefined, {
    selectFromResult: (result) => ({
      data: result.data && jobId ? result.data[jobId] : null,
    }),
  });
  const qualitiesForJob = overview
    ? values(overview).reduce((acc, session) => {
        if (session && session.jobs && jobId && session.jobs[jobId]) {
          const job = session.jobs[jobId];
          toPairs(job.qualities).forEach(([qualityId, checked]) => {
            if (checked) {
              if (!acc[qualityId]) {
                acc[qualityId] = 0;
              }
              acc[qualityId]++;
            }
          });
        }
        return acc;
      }, {} as { [key: string]: number })
    : {};
  const { data: qualities } = useGetQualitiesQuery();

  if (!qualities) {
    return <Layout></Layout>;
  }
  if (!job) {
    return <Layout>job not found</Layout>;
  }

  return (
    <div
      css={css`
        position: fixed;
        background: ${colors.orange};
        border-radius: 20px;
        padding: 20px 0px;
        width: 80%;
        height: 80%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      `}
    >
      <div
        css={css`
          overflow-y: auto;
          padding: 80px 111px;
          height: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
          `}
        >
          <img
            src={trycatch(
              () => require(`assets/illustrations/${job.image}`).default,
              require("assets/illustrations/placeholder.png").default
            )}
            alt={job.name}
            css={css`
              margin-right: 65px;
              width: 230px;
              align-self: flex-start;
            `}
          />
          <div>
            <div
              css={css`
                font-family: Campora Classic;
                font-size: 75px;
                font-style: normal;
                font-weight: 900;
                line-height: 80px;
                letter-spacing: 0em;
                margin-bottom: 20px;
                text-align: left;
                color: ${colors.green};
              `}
            >
              {job.name}
            </div>
            <div
              css={css`
                font-family: Circular Std;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: left;
              `}
            >
              {job.description}
            </div>
          </div>
        </div>
        <div
          css={css`
            margin: 40px auto 0;
            width: fit-content;
            text-align: center;
          `}
        >
          <div
            css={css`
              font-family: Campora Classic;
              font-size: 40px;
              font-style: normal;
              font-weight: 900;
              line-height: 80px;
              letter-spacing: 0em;
              margin-bottom: 20px;
              text-align: left;
              color: ${colors.green};
            `}
          >
            Zo hebben jullie gestemd
          </div>
          <table
            css={css`
              font-family: Circular Std;
              font-size: 18px;
              font-style: normal;
              font-weight: normal;
              line-height: 17px;
              letter-spacing: 0em;
              text-align: left;
              margin: 0 auto;

              border-collapse: collapse;
              td {
                padding: 8px 32px;
              }
              tr {
                border-bottom: 1px solid black;
              }
            `}
          >
            <tbody>
              {toPairs(qualitiesForJob)
                .sort((a, b) => b[1] - a[1])
                .map(([qualityId, count]) => {
                  const quality = qualities[qualityId];
                  return (
                    <tr key={qualityId}>
                      <td
                        css={css`
                          font-weight: bold;
                          text-transform: capitalize;
                        `}
                      >
                        {quality.name}
                      </td>
                      <td>
                        {count} {count === 1 ? "stem" : "stemmen"}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
