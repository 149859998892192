/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import welcome from "assets/images/welcome.png";
import { BsArrowDownCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { colors } from "style/colors";
import { mobile } from "style/mq";
import { Layout } from "../components/Layout";

export const IntroPage = () => {
  return (
    <Layout
      css={css`
        background: ${colors.green};
      `}
    >
      <img
        src={welcome}
        css={css`
          max-width: 90%;
          display: block;
          margin-bottom: 60px;
          display: block;
          margin: 0 auto 20px;
        `}
        alt="welkom bij droombanen"
      />
      <div
        css={css`
          font-family: Campora Classic;
          font-size: 89px;
          font-style: normal;
          font-weight: 800;
          line-height: 103px;
          letter-spacing: 0em;
          text-align: center;
          margin-bottom: 80px;
          ${mobile} {
            font-size: 40px;
          }
        `}
      >
        Droombanen
      </div>
      <Link to="/sign-in" className="button">
        <BsArrowDownCircleFill
          css={css`
            margin-top: 108px;
            color: ${colors.yellow};
            font-size: 80px;
            display: block;
            margin: 0 auto;
          `}
        />
      </Link>
    </Layout>
  );
};
