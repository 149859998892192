import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { current } from "immer";
import { Island, Job, Quality, School, User } from "../types";
import { updateCacheForPuts, updateReferencedObject } from "./utils";

export const baseUrl =
  "https://droombanen-abz-default-rtdb.europe-west1.firebasedatabase.app";

export const droombanenApi = createApi({
  reducerPath: "droombanenApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  endpoints: (builder) => ({
    getSchools: builder.query<{ [key: string]: School }, void>({
      query: () => `/schools.json`,
    }),
    getIslands: builder.query<{ [key: string]: Island }, void>({
      query: () => `/islands.json`,
    }),
    getJobs: builder.query<{ [key: string]: Job }, void>({
      query: () => `/jobs.json`,
    }),
    getQualities: builder.query<{ [key: string]: Quality }, void>({
      query: () => `/qualities.json`,
    }),
    updateSession: builder.mutation<
      any,
      {
        user: User;
        classroom?: string;
        jobIds?: string[];
        qualityIds?: string[];
        school?: string;
        schoolClassroom?: string;
      }
    >({
      query: ({
        user,
        jobIds,
        qualityIds,
        classroom,
        school,
        schoolClassroom,
      }) => ({
        url: `/sessions/${user.uid}.json`,
        method: "PATCH",
        body: {
          ...user,
          classroom,
          school,
          schoolClassroom,
          jobs:
            jobIds && qualityIds
              ? jobIds.reduce(
                  (acc, id) => ({
                    ...acc,
                    [id]: {
                      qualities: qualityIds.reduce(
                        (acc, id) => ({ ...acc, [id]: false }),
                        {}
                      ),
                    },
                  }),
                  {}
                )
              : undefined,
        },
      }),
    }),
    getSession: builder.query<
      User & {
        jobs?: { [key: string]: { qualities: { [key: string]: boolean } } };
      },
      { uid?: string }
    >({
      query: ({ uid }) => `/sessions/${uid}.json`,
    }),
    setQuality: builder.mutation<
      any,
      { qualityId: string; jobId?: string; value: boolean; uid?: string }
    >({
      query: ({ uid, value, jobId, qualityId }) => ({
        url: `sessions/${uid}/jobs/${jobId}/qualities/${qualityId}.json`,
        method: "PUT",
        body: value,
      }),
      async onQueryStarted(
        { uid, value, jobId, qualityId, ...patch },
        { dispatch, queryFulfilled }
      ) {
        console.log(JSON.stringify({ patch }));
        const patchResult = dispatch(
          droombanenApi.util.updateQueryData("getSession", { uid }, (draft) => {
            console.log("cdraft", current(draft), { jobId });
            if (jobId) {
              updateReferencedObject(
                draft,
                ["jobs", jobId, "qualities", qualityId],
                value
              );
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          console.log("uh");
        }
      },
    }),
    getOverview: builder.query<
      {
        [uid: string]: User & {
          schoolClassroom: string;
          jobs?: { [key: string]: { qualities: { [key: string]: boolean } } };
        };
      },
      { classroom?: string; schoolId?: string }
    >({
      query: ({ classroom, schoolId }) => ({
        url: `/sessions.json`,
        params: {
          orderBy: `"schoolClassroom"`,
          equalTo: `"${schoolId}-${classroom}"`,
        },
      }),
      async onCacheEntryAdded(
        { classroom, schoolId },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        if (!classroom || !schoolId) {
          return;
        }
        await updateCacheForPuts<{
          [uid: string]: User & {
            schoolClassroom: string;
            jobs?: { [key: string]: { qualities: { [key: string]: boolean } } };
          };
        }>(
          cacheDataLoaded,
          updateCachedData,
          cacheEntryRemoved,
          `/sessions.json?orderBy="schoolClassroom"&equalTo="${schoolId}-${classroom}"`
        );
      },
    }),
  }),
});

// Export hooks for usage in functional components, which are
export const {
  useGetIslandsQuery,
  useGetJobsQuery,
  useGetQualitiesQuery,
  useSetQualityMutation,
  useGetSessionQuery,
  useUpdateSessionMutation,
  useGetOverviewQuery,
  useGetSchoolsQuery,
} = droombanenApi;
