import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { combineReducers } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { droombanenApi } from "../services/droombanen-api";
import session from "../services/session";
const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [droombanenApi.reducerPath],
};

const reducer = combineReducers({
  [droombanenApi.reducerPath]: droombanenApi.reducer,
  session: session.reducer,
});
const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getdefaultMiddleware) =>
    getdefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(droombanenApi.middleware),
});

const persistor = persistStore(store);
setupListeners(store.dispatch);

export default function configureAppStore() {
  return { store, persistor };
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
