/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Layout } from "components/Layout";
import { last } from "lodash";
import React from "react";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router";
import { useGetOverviewQuery } from "services/droombanen-api";
import { colors } from "style/colors";
import { getLeastControversial } from "utils/controversy";
import { getQualitiesPerJob } from "utils/qualities-per-job";
import { OverviewJob } from "./OverviewJob";

interface Props {}

export const OverviewMostControversial: React.FC<Props> = ({
  children,
  ...props
}) => {
  const { classroom, schoolId } = useParams();
  const { data: overview } = useGetOverviewQuery({ classroom, schoolId });
  const qualitiesPerJob = getQualitiesPerJob(overview);
  const leastControversialJobs = getLeastControversial(qualitiesPerJob);
  const leastControversialJob = last(leastControversialJobs);
  const navigate = useNavigate();

  return (
    <Layout
      css={css`
        background: ${colors.white};
      `}
    >
      {leastControversialJob && (
        <OverviewJob jobId={leastControversialJob[0]} />
      )}
      <div
        css={css`
          position: fixed;
          top: 30px;
          left: 30px;
          background: ${colors.black};
          color: ${colors.white};
          padding: 30px;
          border-radius: 100%;
          width: 300px;
          height: 300px;
          font-family: Campora Classic;
          font-size: 30px;
          font-style: normal;
          letter-spacing: 0em;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          transform: rotate(-10deg);
        `}
      >
        <div>
          Over deze baan zijn jullie het het{" "}
          <span
            css={css`
              background: ${colors.white};
              color: ${colors.black};
              padding: 4px 8px;
            `}
          >
            minst
          </span>{" "}
          met elkaar eens
        </div>
      </div>

      <BsArrowRightCircleFill
        css={css`
          color: ${colors.red};
          font-size: 80px;
          position: fixed;
          bottom: 30px;
          right: 30px;
          cursor: pointer;
        `}
        onClick={() => navigate(`/overview/${schoolId}/${classroom}/list`)}
      />
    </Layout>
  );
};
