/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dropdownIcon from "assets/images/ls_dropdown.png";
import { Layout } from "components/Layout";
import { toPairs } from "lodash";
import React, { useState } from "react";
import { BsArrowDownCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router";
import { useGetSchoolsQuery } from "services/droombanen-api";
import { colors } from "style/colors";

interface Props {}

export const OverviewIndex: React.FC<Props> = ({ children, ...props }) => {
  const { data: schools } = useGetSchoolsQuery();
  const navigate = useNavigate();
  const [selectedSchool, setSelectedSchool] = useState<string>();
  const [selectedClassroom, setSelectedClassroom] = useState<string>();
  return (
    <Layout
      css={css`
        background: ${colors.yellow};
      `}
    >
      <div
        css={css`
          width: 640px;
          max-width: 90%;
          margin: 0 auto 80px;
        `}
      >
        <div
          css={css`
            font-family: Campora Classic;
            font-size: 121px;
            line-height: 112px;
            font-style: normal;
            font-weight: 900;
            letter-spacing: 0em;
            text-align: center;
            color: ${colors.blue};
            margin-bottom: 68px;
          `}
        >
          Selecteer klas
        </div>
        <select
          onChange={(e) => setSelectedSchool(e.target.value)}
          defaultValue="placeholder"
          css={css`
            font-family: Campora Classic;
            font-size: 35px;
            font-style: normal;
            font-weight: 400;
            line-height: 54px;
            letter-spacing: 0em;
            text-align: left;
            height: 84.86846923828125px;
            width: 100%;
            border-radius: 20px;
            border: none;
            padding: 15px 20px;
            -moz-appearance: none; /* Firefox */
            -webkit-appearance: none; /* Safari and Chrome */
            appearance: none;
            position: relative;
            background-image: url(${dropdownIcon});
            background-repeat: no-repeat;
            background-position-x: calc(100% - 20px);
            background-position-y: 50%;
            margin-bottom: 16px;
          `}
        >
          <option disabled hidden value="placeholder">
            Kies je school
          </option>
          {toPairs(schools).map(([id, school]) => (
            <option value={id} key={`school-option-${id}`}>
              {school.name}
            </option>
          ))}
        </select>
        {schools && selectedSchool && (
          <select
            onChange={(e) => setSelectedClassroom(e.target.value)}
            defaultValue="placeholder"
            css={css`
              font-family: Campora Classic;
              font-size: 35px;
              font-style: normal;
              font-weight: 400;
              line-height: 54px;
              letter-spacing: 0em;
              text-align: left;
              height: 84.86846923828125px;
              width: 100%;
              border-radius: 20px;
              border: none;
              padding: 15px 20px;
              -moz-appearance: none; /* Firefox */
              -webkit-appearance: none; /* Safari and Chrome */
              appearance: none;
              position: relative;
              background-image: url(${dropdownIcon});
              background-repeat: no-repeat;
              background-position-x: calc(100% - 20px);
              background-position-y: 50%;
            `}
          >
            <option disabled hidden value="placeholder">
              Kies je klas
            </option>
            {toPairs(schools[selectedSchool].classrooms).map(
              ([id, classroom]) => (
                <option value={id} key={`classroom-option-${id}`}>
                  {classroom.name}
                </option>
              )
            )}
          </select>
        )}
      </div>
      <BsArrowDownCircleFill
        css={css`
          color: ${colors.blue};
          font-size: 80px;
          cursor: pointer;
          opacity: ${selectedClassroom && selectedSchool ? 1 : 0.2};
          margin: 0 auto;
          display: block;
        `}
        onClick={() =>
          selectedClassroom &&
          selectedSchool &&
          navigate(`/overview/${selectedSchool}/${selectedClassroom}`)
        }
      />
    </Layout>
  );
};
