/** @jsxImportSource @emotion/react */
import { Layout } from "components/Layout";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { OverviewJob } from "./OverviewJob";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { css } from "@emotion/react";
import { colors } from "style/colors";

interface Props {}

export const OverviewJobPage: React.FC<Props> = ({ children, ...props }) => {
  const { classroom, schoolId } = useParams();
  const navigate = useNavigate();
  return (
    <Layout
      css={css`
        background: ${colors.white};
      `}
    >
      <OverviewJob />
      <BsArrowLeftCircleFill
        css={css`
          color: ${colors.black};
          font-size: 50px;
          position: fixed;
          top: 30px;
          left: 30px;
          cursor: pointer;
        `}
        onClick={() => navigate(`/overview/${schoolId}/${classroom}/list`)}
      />
    </Layout>
  );
};
