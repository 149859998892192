import { initializeApp } from "@firebase/app";
import { createContext, useContext } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyDN_g4jy5Cr8Ag-pS_UulwiTolm2gQv62s",
  authDomain: "droombanen-abz.firebaseapp.com",
  databaseURL:
    "https://droombanen-abz-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "droombanen-abz",
  storageBucket: "droombanen-abz.appspot.com",
  messagingSenderId: "897831352544",
  appId: "1:897831352544:web:37e2c581c2a699448c7885",
};

const app = initializeApp(firebaseConfig);

export const FirebaseContext = createContext(app);
export const FirebaseProvider: React.FC = (props) => (
  <FirebaseContext.Provider value={app} {...props} />
);
export const useFirebase = () => useContext(FirebaseContext);
