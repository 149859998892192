import { PromiseWithKnownReason } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import {
  MaybeDrafted,
  PatchCollection,
  Recipe,
} from "@reduxjs/toolkit/dist/query/core/buildThunks";
import { FetchBaseQueryMeta } from "@reduxjs/toolkit/query/react";
import { current } from "immer";
import { compact, set } from "lodash";
import { baseUrl } from "./droombanen-api";

export async function updateCacheForPuts<T>(
  cacheDataLoaded: PromiseWithKnownReason<
    {
      data: T;
      meta: FetchBaseQueryMeta | undefined;
    },
    Error & {
      message: "Promise never resolved before cacheEntryRemoved.";
    }
  >,
  updateCachedData: (updateRecipe: Recipe<T>) => PatchCollection,
  cacheEntryRemoved: Promise<void>,
  path: string
) {
  await cacheDataLoaded;
  const evtSource = new EventSource(`${baseUrl}${path}`, {});
  evtSource.addEventListener("put", (response: any) => {
    const event = JSON.parse(response.data);
    const pathComponents: string[] = compact(event.path.split("/") as string[]);
    if (pathComponents.length) {
      updateCachedData((draft) => {
        updateReferencedObject(draft, pathComponents, event.data);
      });
    }
  });
  await cacheEntryRemoved;
  evtSource.close();
}

export function updateReferencedObject<T>(
  draft: MaybeDrafted<T>,
  pathComponents: string[],
  data: any
) {
  if (draft) {
    const [referencedObject, lastPathComponents] = getReferencedObject(
      draft,
      pathComponents
    );
    const firstPathComponent = lastPathComponents.shift();
    let putData = data;
    if (lastPathComponents.length) {
      putData = {};
      set(putData, lastPathComponents, data);
    }
    referencedObject[firstPathComponent] = putData;
  }
}

export function getReferencedObject(
  object: any,
  pathComponents: string[]
): any {
  if (pathComponents.length > 1 && object) {
    const pathComponent = pathComponents.shift();
    try {
      current(object[pathComponent!]);
      return getReferencedObject(object[pathComponent!], pathComponents);
    } catch (e) {
      if (typeof pathComponent === "string") {
        pathComponents.unshift(pathComponent);
      }
      return [object, pathComponents];
    }
  }
  return [object, pathComponents];
}
