/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { colors } from "style/colors";
import { GlobalStyles } from "style/GlobalStyles";
import { useNavigate } from "react-router";
import { mobile } from "style/mq";

export const EilandenLayout: React.FC<{ back?: boolean }> = ({
  children,
  back,
  ...props
}) => {
  const navigate = useNavigate();
  return (
    <div
      css={css`
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        ${mobile} {
          display: block;
          overflow-y: auto;
        }
      `}
      {...props}
    >
      <GlobalStyles />
      <div
        css={css`
          height: 110px;
          padding: 10px 70px;
          display: flex;
          background: ${colors.white};
          ${mobile} {
            padding: 10px 16px;
          }
        `}
      >
        {back && (
          <div
            css={css`
              flex: 1;
            `}
          >
            <BsArrowLeftCircleFill
              css={css`
                font-size: 50px;
                margin-top: 15px;
                cursor: pointer;
                ${mobile} {
                  font-size: 32px;
                  margin-top: 20px;
                }
              `}
              onClick={() => navigate(-1)}
            />
          </div>
        )}
        <Link
          to="/"
          css={css`
            font-family: Campora Classic;
            font-size: 30px;
            font-style: normal;
            font-weight: 900;
            line-height: 70px;
            letter-spacing: 0em;
            text-align: left;
            text-decoration: none;
            color: ${colors.black};
            flex: 1;
            text-align: ${back ? "center" : "left"};
          `}
        >
          Droombanen
        </Link>
        <div
          css={css`
            flex: 1;
          `}
        ></div>
      </div>
      {children}
    </div>
  );
};
