/** @jsxImportSource @emotion/react */
import { css, Global } from "@emotion/react";

export const GlobalStyles: React.FC = () => (
  <Global
    styles={css`
      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }

      #root,
      html,
      body {
        overflow-y: hidden;
      }

      @font-face {
        font-family: "Campora Classic";
        font-style: normal;
        font-weight: 900;
        src: url("/assets/fonts/CamporaClassic-Black.ttf") format("truetype");
      }
      @font-face {
        font-family: "Campora Classic";
        font-style: normal;
        font-weight: 700;
        src: url("/assets/fonts/CamporaClassic-Bold.ttf") format("truetype");
      }
      @font-face {
        font-family: "Campora Classic";
        font-style: normal;
        font-weight: 800;
        src: url("/assets/fonts/CamporaClassic-ExtraBold.ttf")
          format("truetype");
      }
      @font-face {
        font-family: "Campora Classic";
        font-style: normal;
        font-weight: 400;
        src: url("/assets/fonts/CamporaClassic-Regular.ttf") format("truetype");
      }

      @font-face {
        font-family: "Circular Std";
        font-style: normal;
        font-weight: 900;
        src: url("/assets/fonts/CircularStd-Black.ttf") format("truetype");
      }
      @font-face {
        font-family: "Circular Std";
        font-style: italic;
        font-weight: 900;
        src: url("/assets/fonts/CircularStd-BlackItalic.ttf") format("truetype");
      }
      @font-face {
        font-family: "Circular Std";
        font-style: italic;
        font-weight: 700;
        src: url("/assets/fonts/CircularStd-BoldItalic.ttf") format("truetype");
      }
      @font-face {
        font-family: "Circular Std";
        font-style: normal;
        font-weight: 300;
        src: url("/assets/fonts/CircularStd-Book.ttf") format("truetype");
      }
      @font-face {
        font-family: "Circular Std";
        font-style: italic;
        font-weight: 300;
        src: url("/assets/fonts/CircularStd-BookItalic.eot") format("truetype");
      }
      @font-face {
        font-family: "Circular Std";
        font-style: normal;
        font-weight: 400;
        src: url("/assets/fonts/CircularStd-Medium.ttf") format("truetype");
      }
      @font-face {
        font-family: "Circular Std";
        font-style: italic;
        font-weight: 400;
        src: url("/assets/fonts/CircularStd-MediumItalic.ttf")
          format("truetype");
      }

      .slide {
        transition: transform 500ms ease-in-out;
        z-index: 1;
      }

      .slide-enter {
        transform: translateY(100%);
      }

      .slide-enter.slide-enter-active {
        transform: translateY(0%);
      }

      .slide-exit {
        transform: translateY(-100%);
        z-index: 0;
      }

      .slide-exit-active {
        transform: translateY(0%);
        z-index: 0;
      }

      .slide-enter,
      .slide-exit,
      .slide-enter,
      .slide-enter.slide-enter-active,
      .slide-exit,
      .slide-exit-active {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }

      .blobs {
        filter: url("#goo");
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }

      .fineBlobs {
        filter: url("#fineGoo");
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    `}
  />
);
