/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { colors } from "style/colors";
import { useAppSelector } from "../hooks";
import {
  useGetSessionQuery,
  useSetQualityMutation,
} from "../services/droombanen-api";
import { Quality } from "../types";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import { mobile } from "style/mq";

interface Props {
  quality: Quality;
  id: string;
  jobId: string;
  inverted: boolean;
  onInfo: (quality: Quality) => void;
}

export const QualityCheckbox: React.FC<Props> = ({
  children,
  quality,
  jobId,
  id,
  inverted,
  onInfo,
  ...props
}) => {
  const user = useAppSelector((state) => state.session.user);
  const { checked } = useGetSessionQuery(
    {
      uid: user ? user.uid : undefined,
    },
    {
      selectFromResult: ({ data }) => {
        return {
          checked:
            data &&
            data.jobs &&
            data.jobs[jobId] &&
            data.jobs[jobId].qualities[id],
        };
      },
    }
  );
  const [setQuality, setQualityState] = useSetQualityMutation();

  return (
    <div
      {...props}
      css={css`
        height: 45px;
        border: 1px solid ${inverted ? colors.white : colors.black};
        padding: 10px 22px;
        margin: 5px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;
        ${checked &&
        ` background: ${inverted ? colors.white : colors.black};
          color: ${inverted ? colors.black : colors.white};
        `}
        ${setQualityState.isLoading &&
        ` opacity: 0.5;
          cursor: normal;
        `}
        ${mobile} {
          /* justify-content: space-between; */
        }
      `}
      onClick={() => {
        setQuality({
          jobId,
          qualityId: id,
          uid: user ? user.uid : undefined,
          value: !checked,
        });
      }}
    >
      {checked && (
        <BiCheckboxChecked
          css={css`
            font-size: 22px;
            transform: scale(1);
            transition: transform 0.2s;
            &:hover {
              transform: scale(1.2);
            }
            ${mobile} {
              margin: 0;
            }
          `}
        />
      )}
      {!checked && (
        <BiCheckbox
          css={css`
            font-size: 22px;
            transform: scale(1);
            transition: transform 0.2s;
            &:hover {
              transform: scale(1.2);
            }
            ${mobile} {
              margin: 0;
            }
          `}
        />
      )}
      <div
        css={css`
          font-family: Circular Std;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0em;
          text-align: center;
          flex: 1;
          margin: 0 24px;
          ${mobile} {
          }
        `}
      >
        {quality.name}
      </div>
      <AiOutlineInfoCircle
        css={css`
          font-size: 22px;
          transform: scale(1);
          transition: transform 0.2s;
          &:hover {
            transform: scale(1.2);
          }
          ${mobile} {
            margin: 0;
          }
        `}
        onClick={(e) => {
          e.stopPropagation();
          console.log("jaha", quality.name);
          onInfo(quality);
        }}
      />
    </div>
  );
};
