import { Bodies, Body } from "matter-js";
import p5Types from "p5";

export class Circle {
  public body: Body;
  public r: number;
  public label: string;
  public isAttractor: boolean;
  private p5: p5Types;
  constructor(
    p5: p5Types,
    label: string,
    x: number,
    y: number,
    r: number,
    attract: boolean
  ) {
    const body = Bodies.circle(x, y, r, {
      label,
    });
    if (attract) {
      body.plugin = {
        attractors: [
          function (bodyA: Body, bodyB: Body) {
            if (bodyB.label === bodyA.label) {
              return {
                x: (bodyA.position.x - bodyB.position.x) * 1e-6,
                y: (bodyA.position.y - bodyB.position.y) * 1e-6,
              };
            }
          },
        ],
      };
    }
    this.p5 = p5;
    this.body = body;
    this.r = r;
    this.label = label;
    this.isAttractor = attract;
  }

  public show(p5: p5Types) {
    const {
      position: { x, y },
    } = this.body;
    p5.push();
    p5.translate(x, y);
    p5.stroke(0);
    p5.strokeWeight(1);
    p5.noFill();
    p5.circle(0, 0, this.r * 2);
    p5.pop();
  }
}
