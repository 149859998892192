/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Routes, useLocation } from "react-router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useEffect } from "react";

export const AnimatedSwitch: React.FC<{
  direction: string;
  scrollContainer?: boolean;
}> = ({ children, direction, scrollContainer = true }) => {
  const location = useLocation();
  useEffect(() => console.log(location.pathname), [location.pathname]);
  return (
    <div
      css={css`
        ${scrollContainer
          ? `height: 100vh;
        overflow-y: hidden;
        > div {
          height: 100vh;
          overflow-y: hidden;
        }`
          : ""}
      `}
    >
      <TransitionGroup>
        <CSSTransition
          key={location.pathname}
          classNames={`slide slide-${direction} slide`}
          timeout={500}
        >
          <Routes location={location}>{children}</Routes>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};
