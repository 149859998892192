/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useAppSelector } from "hooks";
import { get, values } from "lodash";
import { useState } from "react";
import { useConditionalEffect, useDelta } from "react-delta";
import { AiFillCloseCircle } from "react-icons/ai";
import { useGetSessionQuery } from "services/droombanen-api";
import { colors } from "style/colors";
import { mobile } from "style/mq";

export const VoteCounter = () => {
  const uid = useAppSelector((state) => get(state, "session.user.uid"));
  const { data: session } = useGetSessionQuery({ uid });
  const numVotes = session?.jobs
    ? values(session.jobs).reduce(
        (acc, job) =>
          acc +
          values(job.qualities).reduce((acc, vote) => acc + (vote ? 1 : 0), 0),
        0
      )
    : 0;

  type Badge = {
    title: string;
    description: string;
    earned: boolean;
  };

  const [badges, setBadges] = useState<{ [key: string]: Badge }>({
    "1": {
      title: "Goed bezig!",
      description:
        "Dat ging goed! Laat je weten welke kwaliteiten je bij andere beroepen vindt passen?",
      earned: false,
    },
    "2": {
      title: "Goed bezig!",
      description: "Je hebt tien kwaliteiten ingevuld. Ga zo door!",
      earned: false,
    },
    "3": {
      title: "Goed bezig!",
      description: "Top! Je krijgt het al in de vingers.",
      earned: false,
    },
    "4": {
      title: "Goed bezig!",
      description:
        "Ding ding ding! 30 kwaliteiten heb jij al aangeklikt. Je begint een echte kenner te worden.",
      earned: false,
    },
  });

  const [selectedBadge, setSelectedBadge] = useState<Badge | null>(null);
  const numVotesDelta = useDelta(numVotes);

  useConditionalEffect(() => {
    function earnBadge(key: string) {
      console.log("earned badge", key);
      if (!badges[key].earned) {
        setBadges((badges) => ({
          ...badges,
          key: { ...badges[key], earned: true },
        }));
        setSelectedBadge(badges[key]);
      }
    }
    switch (numVotes) {
      case 3: {
        earnBadge("1");
        break;
      }
      case 10: {
        earnBadge("2");
        break;
      }
      case 20: {
        earnBadge("3");
        break;
      }
      case 30: {
        earnBadge("4");
        break;
      }
    }
  }, numVotesDelta && numVotesDelta.prev && numVotesDelta.curr !== numVotesDelta.prev);

  return (
    <>
      <div
        css={css`
          position: fixed;
          bottom: 16px;
          left: 16px;
          font-family: Campora Classic;
          font-size: 75px;
          font-style: normal;
          font-weight: 900;
          line-height: 80px;
          letter-spacing: 0em;
          margin-bottom: 20px;
          text-align: left;
          z-index: 2;
          ${mobile} {
            font-size: 50px;
            line-height: 44px;
          }
        `}
      >
        {numVotes}
      </div>
      {selectedBadge && (
        <div
          css={css`
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: ${colors.black};
            color: ${colors.white};
            width: 50%;
            padding: 60px 80px;
            border-radius: 20px;
            z-index: 2;
            ${mobile} {
              padding: 32px;
              width: 90%;
              height: auto;
            }
          `}
        >
          <div
            css={css`
              font-family: Campora Classic;
              font-size: 45px;
              font-style: normal;
              font-weight: 900;
              line-height: 60px;
              letter-spacing: 0em;
              text-align: left;
              ${mobile} {
                font-size: 50px;
                line-height: 44px;
                margin-bottom: 40px;
              }
            `}
          >
            {selectedBadge.title}
          </div>

          <div
            css={css`
              font-family: Circular Std;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 30px;
              letter-spacing: 0em;
              text-align: left;
            `}
          >
            {selectedBadge.description}
          </div>
          <AiFillCloseCircle
            css={css`
              font-size: 50px;
              color: ${colors.white};
              position: absolute;
              top: 40px;
              right: 40px;
              cursor: pointer;
              ${mobile} {
                top: 10px;
                right: 10px;
                font-size: 32px;
              }
            `}
            onClick={() => setSelectedBadge(null)}
          />
        </div>
      )}
    </>
  );
};
