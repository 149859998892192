/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { EilandenLayout } from "components/EilandenLayout";
import { JobsBlobs } from "components/JobsBlob";
import { QualityCheckbox } from "components/QualityCheckbox";
import { VoteCounter } from "components/VoteCounter";
import { keys, toPairs } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import {
  useGetIslandsQuery,
  useGetJobsQuery,
  useGetQualitiesQuery,
} from "services/droombanen-api";
import { colors } from "style/colors";
import { mobile } from "style/mq";
import { Job } from "types";
import { trycatch } from "utils/trycatch";
import { Quality } from "../types.d";

interface Props {}

export const Eiland: React.FC<Props> = ({ children, ...props }) => {
  const jobElementRef = useRef<HTMLDivElement | null>(null);
  const { islandId } = useParams();
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const { island } = useGetIslandsQuery(undefined, {
    selectFromResult: ({ data }) => {
      return { island: data ? data[islandId!] : undefined };
    },
  });
  const { jobs } = useGetJobsQuery(undefined, {
    selectFromResult: ({ data }) =>
      data
        ? {
            jobs: island
              ? toPairs(data).filter(([id]) => keys(island.jobs).includes(id))
              : [],
          }
        : { jobs: [] },
  });
  const { data: qualities = {} } = useGetQualitiesQuery();
  const [selectedQuality, setSelectedQuality] = useState<Quality | null>(null);
  const [nextJob, setNextJob] = useState<Job | null>(null);
  const [previousJob, setPreviousJob] = useState<Job | null>(null);
  useEffect(() => {
    if (selectedJob) {
      const sortedJobs = jobs
        .map(([id, job]) => job)
        .sort((a, b) => (a.id > b.id ? 1 : -1));
      const selectedJobIndex = sortedJobs.findIndex(
        (job) => job.id === selectedJob.id
      );
      if (selectedJobIndex === 0) {
        setPreviousJob(sortedJobs[sortedJobs.length - 1]);
      } else {
        setPreviousJob(sortedJobs[selectedJobIndex - 1]);
      }
      if (selectedJobIndex >= sortedJobs.length - 1) {
        setNextJob(sortedJobs[0]);
      } else {
        setNextJob(sortedJobs[selectedJobIndex + 1]);
      }
    }
  }, [selectedJob, jobs]);

  useEffect(() => {
    if (selectedJob && jobElementRef && jobElementRef.current) {
      jobElementRef.current.scrollTo({ top: 0 });
    }
  }, [selectedJob, jobElementRef]);

  return (
    <EilandenLayout back>
      <VoteCounter />
      <div
        css={css`
          flex: 1;
          padding: 0 70px;
          position: relative;
          background: ${colors.white};
          display: flex;
          ${mobile} {
            display: block;
            padding: 0 32px;
          }
        `}
      >
        <div
          css={css`
            flex: 1;
          `}
        >
          <div
            css={css`
              font-family: Campora Classic;
              font-size: 121px;
              font-style: normal;
              font-weight: 900;
              line-height: 112px;
              letter-spacing: 0em;
              text-align: left;
              color: ${island?.colors.background};
              margin-bottom: 65px;
              ${mobile} {
                font-size: 60px;
                line-height: 70px;
                margin-bottom: 35px;
              }
            `}
          >
            {island?.name}
          </div>
          <div
            css={css`
              font-family: Circular Std;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 30px;
              letter-spacing: 0em;
              text-align: left;
              ${mobile} {
                font-size: 14px;
                line-height: 16px;
              }
            `}
          >
            {island?.description}
          </div>
        </div>
        <div
          css={css`
            flex: 1;
            position: relative;
            margin-left: 10vw;
            ${mobile} {
              height: 100vh;
              width: 100%;
            }
          `}
        >
          {island && (
            <JobsBlobs
              color={island!.colors.background}
              textColor={island!.colors.body}
              jobs={jobs}
              islandId={islandId}
              onClickJob={(jobId) => {
                const job = jobs.find(([id]) => id === jobId);
                if (job && job[1]) {
                  setSelectedJob(job[1]);
                }
              }}
            />
          )}
        </div>
      </div>
      {selectedJob && (
        <div
          css={css`
            position: fixed;
            background: ${island?.colors.background};
            border-radius: 20px;
            padding: 20px 0px;
            width: 80%;
            height: 80%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: ${island?.colors.body};
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
            ${mobile} {
              width: 90%;
              height: 90%;
            }
          `}
        >
          <div
            ref={jobElementRef}
            css={css`
              overflow-y: auto;
              padding: 80px 111px;
              height: 100%;
              ${mobile} {
                padding: 40px 32px;
              }
            `}
          >
            <AiFillCloseCircle
              css={css`
                font-size: 50px;
                color: ${island?.colors.body};
                position: fixed;
                top: 40px;
                right: 40px;
                cursor: pointer;
                ${mobile} {
                  top: 10px;
                  right: 10px;
                  font-size: 32px;
                }
              `}
              onClick={() => {
                setSelectedJob(null);
                setSelectedQuality(null);
              }}
            />
            {previousJob && (
              <BsArrowLeftCircleFill
                css={css`
                  font-size: 50px;
                  cursor: pointer;
                  position: fixed;
                  left: 31px;
                  top: 50%;
                  transform: translateY(-50%);
                  ${mobile} {
                    left: -10px;
                    font-size: 32px;
                  }
                `}
                onClick={() => setSelectedJob(previousJob)}
              />
            )}
            {nextJob && (
              <BsArrowRightCircleFill
                css={css`
                  font-size: 50px;
                  cursor: pointer;
                  position: fixed;
                  right: 31px;
                  top: 50%;
                  transform: translateY(-50%);
                  ${mobile} {
                    right: -10px;
                    font-size: 32px;
                  }
                `}
                onClick={() => setSelectedJob(nextJob)}
              />
            )}
            <div
              css={css`
                display: flex;
                ${mobile} {
                  display: block;
                }
              `}
            >
              <img
                src={trycatch(
                  () =>
                    require(`assets/illustrations/${selectedJob.image}`)
                      .default,
                  require("assets/illustrations/placeholder.png").default
                )}
                alt={selectedJob.name}
                css={css`
                  margin-right: 65px;
                  width: 230px;
                  max-width: 100%;
                  align-self: flex-start;
                  ${mobile} {
                    width: 20vh;
                    margin: 0 auto;
                    display: block;
                  }
                `}
              />
              <div>
                <div
                  css={css`
                    font-family: Campora Classic;
                    font-size: 75px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 80px;
                    letter-spacing: 0em;
                    margin-bottom: 20px;
                    text-align: left;
                    color: ${island?.colors.text};
                    ${mobile} {
                      font-size: 30px;
                      line-height: 36px;
                      text-align: center;
                    }
                  `}
                >
                  {selectedJob.name}
                </div>
                <div
                  css={css`
                    font-family: Circular Std;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 30px;
                    letter-spacing: 0em;
                    text-align: left;
                    ${mobile} {
                      font-size: 16px;
                      line-height: 20px;
                    }
                  `}
                >
                  {selectedJob.description}
                </div>
              </div>
            </div>
            <div
              css={css`
                font-family: Campora Classic;
                font-size: 30px;
                font-style: normal;
                font-weight: 900;
                line-height: 35px;
                letter-spacing: 0em;
                text-align: center;
                margin-top: 32px;
                margin-bottom: 29px;
                color: ${island?.colors.text};
                ${mobile} {
                  font-size: 26px;
                  line-height: 30px;
                }
              `}
            >
              Welke kwaliteiten vind jij bij dit beroep horen?
            </div>
            <div
              css={css`
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                ${mobile} {
                  display: block;
                }
              `}
            >
              {selectedJob &&
                toPairs(qualities).map(([id, quality]) => (
                  <QualityCheckbox
                    key={`quality-${selectedJob.id}-${id}`}
                    onInfo={(quality) => setSelectedQuality(quality)}
                    inverted={island?.colors.body === "#ffffff"}
                    {...{ quality, id, jobId: selectedJob.id }}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
      {selectedQuality && (
        <div
          css={css`
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: ${colors.black};
            color: ${colors.white};
            width: 50%;
            padding: 60px 80px;
            border-radius: 20px;
            border: 2px solid ${island?.colors.background};
          `}
        >
          <div
            css={css`
              font-family: Campora Classic;
              font-size: 45px;
              font-style: normal;
              font-weight: 900;
              line-height: 60px;
              letter-spacing: 0em;
              text-align: left;
            `}
          >
            {selectedQuality.name}
          </div>

          <div
            css={css`
              font-family: Circular Std;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 30px;
              letter-spacing: 0em;
              text-align: left;
            `}
            dangerouslySetInnerHTML={{ __html: selectedQuality.description }}
          />
          <AiFillCloseCircle
            css={css`
              font-size: 50px;
              color: ${colors.white};
              position: absolute;
              top: 40px;
              right: 40px;
              cursor: pointer;
            `}
            onClick={() => setSelectedQuality(null)}
          />
        </div>
      )}
    </EilandenLayout>
  );
};
